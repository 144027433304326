import WeeklyBrunch from "./weekly-brunch.json";
import WeekendBrunch from "./weekend-brunch.json";
import Breakfast from "./breakfast.json";
import Cocktails from "./cocktails.json";
import Drinks from "./drinks.json";

export default {
  namespaced: true,
  state: {
    menus: [WeeklyBrunch, WeekendBrunch, Breakfast, Cocktails, Drinks]
  },
  getters: {
    getMenu: state => uri => state.menus.find(m => m.uri === uri)
  }
};
