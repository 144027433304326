// const BASE_URL = "https://source.unsplash.com/";

export default {
  namespaced: true,
  state: {
    url: "",
    images: [
      {
        url: "https://ik.imagekit.io/kreatikart/patrol_paris/interior-min.jpg"
      },
      {
        url:
          "https://ik.imagekit.io/kreatikart/patrol_paris/interior_library-min.jpg"
      },
      {
        url:
          "https://ik.imagekit.io/kreatikart/patrol_paris/interior-stairs-min.jpg"
      },
      {
        url: "https://ik.imagekit.io/kreatikart/patrol_paris/interior-2-min.jpg"
      },
      {
        url:
          "https://ik.imagekit.io/kreatikart/patrol_paris/interior-library-min.jpg"
      },
      {
        url:
          "https://ik.imagekit.io/kreatikart/patrol_paris/brunch-table-4-min.jpg"
      },
      {
        url:
          "https://ik.imagekit.io/kreatikart/patrol_paris/brunch-table-3-min.jpg"
      },
      { url: "https://ik.imagekit.io/kreatikart/patrol_paris/exterior-min.jpg" }
    ]
  },
  getters: {
    getImage: state => state.images.find(i => i.url === state.url),
    getImages: state => state.images
  },
  mutations: {
    OPEN(state, { url }) {
      state.url = url;
    },
    CLOSE(state) {
      state.url = "";
    }
  }
};
