import router from "../router";

import emailjs, { init } from "emailjs-com";
init("user_xlFH1drPXqU0ilGDvHR7f");

export default {
  namespaced: true,
  state: {
    loading: false,
    message: ""
  },
  getters: {
    isLoading: state => state.loading,
    getMessage: state => state.message
  },
  mutations: {
    SEND_EMAIL(state) {
      state.loading = true;
      state.message = "";
    },
    SEND_EMAIL_SUCCESS(state) {
      state.loading = false;
      state.message = "Message envoyé !";
    },
    SEND_EMAIL_ERROR(state) {
      state.loading = false;
      state.message = "Une erreur est survenue, veuillez ressayer plus tard.";
    }
  },
  actions: {
    async sendEmail({ commit, state }, data) {
      let template;
      switch (data.object) {
        case "com":
          template = "patrol_com";
          break;
        default:
          template = "patrol_general";
      }
      commit("SEND_EMAIL");
      try {
        await emailjs.send("service_2br5jdb", template, {
          from_name: data.name,
          reply_to: data.email,
          message: data.message
        });
        commit("SEND_EMAIL_SUCCESS");
        setTimeout(() => {
          state.message = "";
          router.go(-1);
        }, 3500);
      } catch (e) {
        commit("SEND_EMAIL_ERROR");
      }
    }
  }
};
